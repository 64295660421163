.signinContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  max-width: 360px;
  padding: 0 20px;
  margin: 0 auto;

  header {
    height: 56px;
    display: flex;
    align-items: center;

    img {
      width: 100%;
      height: 32px;
    }
  }

  footer {
    font-size: 14px;
    color: var(--gray700);
  }
}

.signinImage {
  width: 100%;
}

.imageTitle {
  text-align: center;
  padding: 5px 0 0;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.5em;
  height: 2.5em;
}

.rotatingContainer {
  position: relative;
  color: rgba(0, 0, 0, 0);
  padding: 0 4px;

  .rotatingText {
    color: black;
    position: absolute;
    margin-top: -5px;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;

    &:nth-of-type(1) {
      animation-name: rotate-frist;
      animation-duration: 6s;
      animation-iteration-count: infinite;
    }

    &:nth-of-type(2) {
      animation-name: rotate-second;
      animation-duration: 6s;
      animation-iteration-count: infinite;
    }

    &:nth-of-type(3) {
      animation-name: rotate-last;
      animation-duration: 6s;
      animation-iteration-count: infinite;
    }
  }
}

@keyframes rotate-frist {
  0% {
    opacity: 0;
    transform: translate3d(0, 25px, 0);
  }

  7%,
  22% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  33% {
    opacity: 0;
    transform: translate3d(0, -25px, 0);
  }
}

@keyframes rotate-second {
  33% {
    opacity: 0;
    transform: translate3d(0, 25px, 0);
  }

  40%,
  55% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  66% {
    opacity: 0;
    transform: translate3d(0, -25px, 0);
  }
}

@keyframes rotate-last {
  66% {
    opacity: 0;
    transform: translate3d(0, 25px, 0);
  }

  73%,
  88% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, -25px, 0);
  }
}

.authContent {
  text-align: center;
  word-break: keep-all;
  font-size: 16px;
  padding: 0 0 20px;
  max-width: 260px;
  margin: 0 auto;
  color: var(--gray700);
}