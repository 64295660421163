html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

:root {
  --orange400: #E68819;
  --orange500: #DA7B11;
  --orange600: #CB6F10;
  --orange700: #BD640D;

  --yellow100: #fffbea;
  --yellow300: #ffea9d;

  --red100: #fdf1f2;
  --red300: #fed7d6;
  --red400: #E34850;
  --red500: #D7373F;
  --red600: #C9252D;
  --red700: #BB121A;

  --blue400: #1890ff;

  --gray75: #FAFAFA;
  --gray100: #F5F5F5;
  --gray200: #EAEAEA;
  --gray300: #E1E1E1;
  --gray400: #C4C4C4;
  --gray500: #B3B3B3;
  --gray600: #8E8E8E;
  --gray700: #6E6E6E;
  --gray800: #4B4B4B;
  --gray900: #2C2C2C;

  --backdrop: rgba(255, 255, 255, 0.4);
  --unable: rgba(0, 0, 0, 0.4);

  // border가 있기 때문에 실제로는 1px을 더해야한다.
  --page-bottom-padding: 140px;
  --card-shadow: 0px 5px 20px 5px #eeeeee;
}

.ant-list-item-meta-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis
}