.previewContainer {
  padding: 20px;
  margin: 0 auto;

  @media (min-width: 640px) {
    max-width: 70vw;
  }
}

.lectureTitle {
  padding: 30px 0;

  h2 {
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
  }
}

.lectureInfo {
  font-size: 14px;
  line-height: 1.5;
  color: var(--gray700);

  b {
    color: var(--gray900);
    padding-bottom: 4px;
  }

  p {
    font-size: 14px;
    position: relative;
    padding-left: 10px;

    &::before {
      content: "·";
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}