.movieContainer {
  box-shadow: var(--card-shadow);
  margin-bottom: 20px;
  padding: 10px 20px;
  border-radius: 8px;
  // FRAME SET
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;

  .respIframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}