.lectureList {
  max-width: 480px;
  margin: 0 auto;
}

.item {

  h4,
  h3 {
    margin: 0;
  }

  .lectureDescription {
    font-size: 15;
    color: var(--gray700);
  }
}