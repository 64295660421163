.classroomCard {
  box-shadow: 0px 4px 10px 3px rgba(160, 200, 230, 0.3);
  border-radius: 8px;
  padding: 20px 16px;
  display: flex;
  align-items: center;
  max-width: 480px;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.left {
  flex: 1;

  h3 {
    margin: 0;
    font-size: 16px;
    padding-bottom: 20px;
  }

  p {
    margin: 0;
    font-size: 14px;
    color: var(--gray700);
  }
}